import React from "react";
import BlogDetailsLeftSide from "../../../components/BlogDetailsLeftSide/BlogDetailsLeftSide";

const BlogDetailsArea = ({ brigade }) => {
  return (
    <>
      <section className="blog-area pt-120 pb-80">
        <div className="container">
          <div className="row">
            <BlogDetailsLeftSide
              brigade={brigade}
              details_topBar={
                <div className="postbox__thumb mb-35">
                  {brigade === "1" ? (
                    <img
                      src="/img/slider/brigada_one/slide_two.jpg"
                      alt="Blog img"
                    />
                  ) : (
                    <img
                      src="/img/slider/brigada_one/slide_five.jpg"
                      alt="Blog img"
                    />
                  )}
                </div>
              }
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetailsArea;
