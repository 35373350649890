import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Cervicometria = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Cervicometría" />
      <section className="about-area pt-40 pb-40">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="section-title section-title-m-0 pos-rel text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="Cervicometría Img"
                    src="img/services/tamizajadae_mix.png"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  Mediante ecografía transvaginal se revisa el cuello del útero,
                  para valorar su longitud, la apertura de los orificios,
                  detritus en liquido amniótico, y abombamiento por el paso de
                  las membranas amnióticas a través de él.
                </p>
                <p>
                  Indicada en paciente con antecedente de parto pretermino,
                  insuficiencia cervical, antecedente de procedimientos en el
                  cuello uterino (conizacion cervical, cerclaje y uso de
                  progesterona en gestación previa) apartir de las 14 semanas de
                  embarazo y en cualquier semana de la gestación ante
                  diagnositco de síndrome de parto pretermino hasta semana 34,
                  para la toma decisiones
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Cervicometria;
