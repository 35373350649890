import React from "react";
import { Link } from "react-router-dom";

const HomeSingleBlogLeftSide = ({ image, title, description, brigade }) => {
    return (
        <>
            <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="latest-news-box mb-30">
                    <div className="latest-news-thumb mb-35">
                        <img src={`img/blog/blog-thumb-${image}.png`} alt="" />
                    </div>
                    <div className="latest-news-content">
                        <h3>
                            <Link
                                to={`/blogDetails/${brigade}`}
                                params={{ brigade: brigade }}
                            >
                                {title}
                            </Link>
                        </h3>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeSingleBlogLeftSide;
