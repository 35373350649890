import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Diu = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Inserción y retiro de dispositivo intrauterino" />
      <section className="about-area pt-40 pb-40">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="section-title section-title-m-0 pos-rel text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="DIU"
                    className="round-image"
                    src="img/services/diu.jpg"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  Método de planificación familiar que actúa como medio de
                  barrera y químico (T de cobre) para la prevención de embarazo,
                  se coloca el primer o segundo día del ciclo menstrual Duración
                  estimada del procedimiento 30 minutos.
                </p>
                <p>
                  <b>Dispositivo intrauterino MIRENA</b>
                  <br />
                  <br /> Método de planificación familiar que actúa a nivel
                  local (genitales internos utero trompas y ovarios) mediante la
                  liberacion hormonal continua para la prevención de embarazo,
                  se coloca el primer o segundo día del ciclo menstrual.
                  Duración estimada del procedimiento 30 minutos.
                </p>
                <p>
                  Incluye colocación del dispositivo bajo visión ecográfica y
                  control al mes con ecografía pélvica transvaginal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Diu;
