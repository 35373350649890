import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../../components/Shared/Footer";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";
import DoctorDetailsArea from "./DoctorDetailsArea/DoctorDetailsArea";

const DoctorDetails = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Dra. Judith Zubieta" subtitle="Details" />
      <DoctorDetailsArea />
      <HomeFooter />
    </>
  );
};

export default DoctorDetails;
