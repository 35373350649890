import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const TestAdnVph = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Test ADN & VPH" />
      <section className="about-area pt-40 pb-40">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="section-title section-title-m-0 pos-rel text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="Test Adn Vph Img"
                    className="round-image"
                    src="img/services/test_adn_vph.jpg"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  En esta prueba, se buscan partículas del ADN de 14 tipos de
                  VPH en las células del cuello uterino. Esta prueba se puede
                  hacer por sí sola (prueba primaria del VPH) o en el momento en
                  que se realiza la prueba de Papanicolaou o citología vaginal
                  (prueba conjunta).
                </p>
                <p>
                  Indicada preferiblemente para la detección del cáncer de
                  cuello uterino de mujeres entre los 30 y 65 años de edad. Si
                  su resultado es positivo o anormal, requiere la realización de
                  colposcopia biopsia para el diagnóstico definitivo de lesión
                  por el virus de papiloma humano a nivel del cuello uterino y
                  el riesgo y/o establecimiento del cáncer de cuello de útero.
                </p>

                <p>
                  Para la realización de este examen: Recuerda, tres días antes
                  no puedes usar óvulos, tener la regla o menstruación, ni
                  relaciones sexuales. Acude en ropa cómoda (idealmente vestido
                  o falda) y trae un protector (algunas pacientes presentan
                  manchado vaginal posterior a la realización del examen).
                </p>
                <p> Duración estimada del examen 15 minutos.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default TestAdnVph;
