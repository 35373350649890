import React from "react";

const HomeAboutArea = () => {
  return (
    <>
      <section className="about-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 about_left">
              <div className="medical-icon-brand-2">
                <img src="img/about/medical-brand-icon-border.png" alt="" />
              </div>
              <div className="about-left-side pos-rel mb-30">
                <div className="about-front-img">
                  <img src="img/about/about-img.jpg" alt="" />
                </div>
                <div className="about-shape">
                  <img src="img/about/about-shape.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="about-right-side pt-55 mb-30">
                <div className="about-title mb-20">
                  {/* <h5>Acerca de mi</h5> */}
                  <h1>Un poco de mi ...</h1>
                </div>
                <div className="about-text">
                  <p>
                    Un sueño que cada noche pedí, una atracción inevitable al
                    aprendizaje semiologico obstétrico y ginecológico mientras
                    era estudiante de medicina, que al pasar los años y trabajar
                    con ellas creció; Fuí amando cada detalle de su creación y
                    sus particularidades... Somos únicas!! Desde la concepción,
                    de unas cuantas células a un ser humano tan perfecto, de la
                    niñez, su dulzura y cuidado, la temida y difícil
                    adolescencia, de su transformación sublime y definitiva como
                    es el embarazo y la maternidad, dar vida y ser la vida de
                    alguien de forma incondicional, llegar a su vejez y ser
                    siempre fuerte, amorosa, guerrera y soñadora... y SI, me
                    enamoré de las mujeres y decidí que siendo una de ellas yo
                    sería un instrumento para acompañarlas en todas las etapas
                    de su vida.
                  </p>
                  <br />
                </div>
                <div className="about-author d-flex align-items-center">
                  {/* <div className="author-ava">
                    <img src="img/about/author-ava.png" alt="" />
                  </div> */}
                  <div className="author-desination">
                    <br />
                    <br />
                    <br />
                    <h4>Judith Zubieta</h4>
                    <h6>Especialista en ginecología y obstetricia</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAboutArea;
