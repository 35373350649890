import React from "react";
// import useGlobalContext from "../../hooks/useGlobalContext";
import { sendWhatsapp } from "../../utils";

const HomeHeroSingleSlide = ({ bg_className }) => {
  // const videoPopup = useGlobalContext();
  // const { setIsOpen } = videoPopup;
  return (
    <>
      <div
        className={`single-slider slider-height d-flex align-items-center slider_bg_${bg_className}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="hero-text">
                <div className="hero-slider-caption ">
                  <h5>Estamos para tu bienestar.</h5>
                  <h1 className="hero-slider-text">
                    Las mejores practicas para tu salud.
                  </h1>
                  {/* <p>Las mejores practicas para tu salud.</p> */}
                </div>
                <div className="hero-slider-btn">
                  <span
                    className="primary_btn btn-icon ml-0"
                    onClick={() => sendWhatsapp()}
                  >
                    <span>+</span>Agenda tu cita
                  </span>

                  <button
                    // onClick={() => setIsOpen(true)}
                    className="play-btn popup-video"
                  >
                    <i className="fas fa-play"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHeroSingleSlide;
