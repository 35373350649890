/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../../components/Shared/Sidebar/Sidebar";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import { sendWhatsapp } from "../../../../utils";

const HomeHeader = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { stickyMenu } = useGlobalContext();

  return (
    <>
      <header>
        <div className="top-bar d-none d-md-block">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1 col-md-7 offset-md-1">
                <div className="header-info">
                  <span>
                    <i className="fas fa-phone"></i> 3153260152 - 3142720950
                  </span>
                  <span>
                    <i className="fas fa-envelope"></i> drazubieta@gmail.com
                  </span>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-4">
                <div className="header-top-right-btn f-right">
                  <span className="primary_btn" onClick={() => sendWhatsapp()}>
                    Agenda tu cita
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"
          }
        >
          <div className="container menu_wrapper">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                <div className="logo logo-circle pos-rel">
                  <Link to="/">
                    <img src="img/logo/logo.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                <div className="header-right f-right">
                  <div className="header-social-icons f-right d-none d-xl-block">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="header__menu f-right">
                  <nav id="mobile-menu">
                    <ul>
                      <li>
                        <Link to="/">Inicio +</Link>
                      </li>
                      <li>
                        <Link to="/">Consultas +</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/obstetricia">
                              Obstetricia de bajo y alto riesgo
                            </Link>
                          </li>
                          <li>
                            <Link to="/ginecologia">Ginecología</Link>
                          </li>
                          <li>
                            <Link to="/anticoncepcion">
                              Anticoncepción o planificación familiar
                            </Link>
                          </li>
                          <li>
                            <Link to="/lactancia">Lactancia materna</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/">Ecografías +</Link>
                        <ul className="submenu">
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdownMenuLink"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Obstetricia
                            </a>

                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdownMenuLink"
                            >
                              <li>
                                <Link to="/transvaginal">
                                  Obstétrica transvaginal o temprana
                                </Link>
                              </li>
                              <li>
                                <Link to="/transabdominal">
                                  Obstétrica transabdominal
                                </Link>
                              </li>
                              <li>
                                <Link to="/biofisico">
                                  Obstétrica con perfil biofísico
                                </Link>
                              </li>
                              <li>
                                <Link to="/tamizaje">
                                  Tamizaje genético o Translucencia nucal
                                </Link>
                              </li>
                              <li>
                                <Link to="/detalle_anatomico">
                                  Detalle anatómico
                                </Link>
                              </li>
                              <li>
                                <Link to="/cervicometria">Cervicometría</Link>
                              </li>
                              <li>
                                <Link to="/doppler_fetoplacentario">
                                  Doppler fetoplacentario
                                </Link>
                              </li>
                              <li>
                                <Link to="/ecografia_3d_4d">
                                  Ecografía 3D – 4D
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdownMenuLink"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Ginecología
                            </a>

                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdownMenuLink"
                            >
                              <li>
                                <Link to="/ecografia/transvaginal">
                                  Ecografía pélvica transvaginal
                                </Link>
                              </li>
                              <li>
                                <Link to="/ecografia/transabdominal">
                                  Ecografía pélvica transabdominal
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="/">Otros servicios +</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/other-citologia">
                              Toma de citología vaginal
                            </Link>
                          </li>
                          <li>
                            <Link to="/other-diu">
                              Inserción y retiro de dispositivo intrauterino
                            </Link>
                          </li>
                          <li>
                            <Link to="/other-subdermico">
                              Inserción y retiro de implante subdérmico
                            </Link>
                          </li>
                          <li>
                            <Link to="/other-test_adn_vph">
                              Toma de test ADN VPH
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="/doctorDetails">Dra. Judith +</a>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div className="side-menu-icon d-lg-none text-end">
                  <button
                    onClick={handleShow}
                    className="side-toggle border-0 bg-transparent"
                  >
                    <i className="fas fa-bars"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};

export default HomeHeader;
