import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Eco3d = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Ecografía 3D y 4D" />
      <section className="about-area pt-40 pb-40">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="section-title section-title-m-0 pos-rel text-end">
                <div className="section-text section-text-small pos-rel">
                  <img alt="Doppler Img" src="img/services/eco3d4d.png" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  Mediante la ecografía 3D (Tercera dimensión) - 4D (Cuarta
                  dimensión), de alta definición, los padres pueden visualizar
                  con claridad a su bebe, ver su rostro, gestos, confirmar el
                  sexo fetal, y confirmar hallazgos anormales en ecografías
                  previas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Eco3d;
