import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Biofisico = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Obstétrica con perfil biofísico" />
      <section className="about-area pt-40 pb-60">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="section-title section-title-m-0 pos-rel  text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="Perfil biofisico"
                    src="img/services/biofisico.png"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  En esta ecografía se evalua el bienestar fetal a través de 4
                  parametros: medición del líquido amniótico, visualización de
                  movimientos respiratorios y del bebe, visualización del tono
                  del bebe, idealmente se realiza apartir de semana 32,
                  finalmente otorga un puntaje que permite la toma de
                  decisiones.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Biofisico;
