import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Tamizaje = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Tamizaje genético o Translucencia nucal" />
      <section className="about-area pt-40 pb-40">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="section-title section-title-m-0 pos-rel text-end">
                <div className="section-text section-text-small pos-rel">
                  <img alt="Tamizaje Img" src="img/services/tamizaje_mix.png" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  Realizada entre las semanas 11 a 13+6 días cómo máximo,
                  permite conocer con exactitud la edad gestacional, revisar al
                  bebe para confirmar que sus órganos están bien formados, y
                  buscar imágenes especiales (marcadores de alteraciones
                  cromosómicas) como la sonolucencia nucal, y presencia o no de
                  hueso nasal, que aumenta la posibilidad de detección del
                  Síndrome Down (“Mongolismo”) y otras anomalías fetales.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Tamizaje;
