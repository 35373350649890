import React from "react";
import HomeSingleBlogLeftSide from "../../../../components/HomeSingleBlogLeftSide/HomeSingleBlogLeftSide";

const HomeBlogsTwo = () => {
  return (
    <>
      <section className="latest-news-area pt-115 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7">
              <div className="section-title pos-rel mb-75">
                <div className="section-icon">
                  <img
                    className="section-back-icon back-icon-left"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text pos-rel">
                  <h1>Servicio Social</h1>
                </div>
                <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <HomeSingleBlogLeftSide
              image="3"
              title="Primera brigada 14 de noviembre 2021"
              description=""
              brigade={1}
            />
            <HomeSingleBlogLeftSide
              image="4"
              title="Segunda brigada 5 de marzo 2022"
              description=""
              brigade={2}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBlogsTwo;
