import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Anticoncepcion = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Anticoncepción o planificación familiar" />
      <section className="about-area pt-20 pb-50">
        <div className="container">
          <div className="row ">
            <div className="col-xl-12 col-lg-12 col-md-12  d-flex align-items-center justify-content-center">
              <div className="facalty-text">
                <p>
                  La finalidad de esta consulta será la orientación para la toma
                  de decisiones individuales o en pareja para la prevención de
                  embarazos de acuerdo con su proyecto de vida, eligiendo el
                  método más eficaz y seguro respetando las creencias y
                  situación personal.
                </p>
                <figure className="d-flex align-items-center justify-content-center">
                  <img
                    alt="Anticoncepcion"
                    src="img/services/planificacion_mix.png"
                    width={"50%"}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Anticoncepcion;
