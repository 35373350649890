import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";

import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const EcoTransabdominal = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Ecografía pélvica transabdominal" />
      <section className="about-area pt-50 pb-50">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="section-title section-title-m-0 pos-rel  text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="Ecografía pélvica transabdominal"
                    src="../img/services/eco_transabdominal.jfif"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="facalty-text">
                <p>
                  Esta ecografía permite examinar los órganos genitales internos
                  de una mujer, por via suprapubica o abdominal, indicada en
                  pacientes sin antecedente de actividad sexual, desarrollo o
                  pubertad precoz, dolor pélvico, sangrados anormales.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default EcoTransabdominal;
