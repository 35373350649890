import React from "react";
import HomeSIngleService from "../../../../components/HomeSIngleService/HomeSIngleService";

const HomeServices = () => {
  return (
    <>
      <section className="servcies-area gray-bg pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div className="section-title text-center pos-rel mb-75">
                <div className="section-icon">
                  <img
                    className="section-back-icon"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text pos-rel home_ser_title">
                  <h5>Servicios</h5>
                  <h1>Tu salud es lo primero</h1>
                </div>
                <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <HomeSIngleService
              icon="2"
              title="Consultas"
              description="Valoración especializada e integral para la mujer en todas las etapas de su vida"
            />
            <HomeSIngleService
              icon="3"
              title="Ecografías"
              description="Estudio de imágenes através de ondas sonoras que permite valorar los órganos pelvicos y / o el bebé en desarrollo"
            />
            <HomeSIngleService
              icon="1"
              title="Otros"
              description="Toma de muestras ginecológicas, anticoncepción y lactancia"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeServices;
