import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Transabdominal = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Obstétrica transabdominal" />
      <section className="about-area pt-50 pb-50">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="section-title section-title-m-0 pos-rel  text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="Foto embarazada sentada hablando ...."
                    src="img/services/transabdominal.png"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="facalty-text">
                <p>
                  Se realiza apartir de las 14 semanas de embarazo y hasta
                  finalizar la gestación, permite analizar el ambiente
                  intrauterino (ubicación de la placenta, cantidad de líquido
                  amniótico, el útero), definir si el embarazo es único o
                  múltiple, y realizar una valoración global del bebe, tomarle
                  medidas y de esta manera establecer edad gestacional,
                  crecimiento y posición para el parto
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Transabdominal;
