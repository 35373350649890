import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Subdermico = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Inserción y retiro de implante subdérmico" />
      <section className="about-area pt-40 pb-40">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="section-title section-title-m-0 pos-rel text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="subdermico Img"
                    className="round-image"
                    src="img/services/subdermico.jpg"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  Método de planificación familiar que actúa mediante liberación
                  hormonal continua para la prevención de embarazo, se coloca en
                  el brazo no dominante previa prueba de embarazo negativa en
                  cualquier día del ciclo menstrual. Duración estimada del
                  procedimiento 30 minutos.
                </p>
                <p>Incluye colocación del dispositivo y control al mes.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Subdermico;
