import React from "react";
import { Offcanvas } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Collapsible from "react-collapsible";

/**
 * Menu movil
 */
const Sidebar = ({ show, handleClose }) => {
  const Department = <span>Consultas</span>;
  const Doctors = <span>Ecografías </span>;
  const News = <span>Otros servicios</span>;
  const Pages = <span>Dra. Judith</span>;
  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="side__bar"
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Collapsible
              trigger={Department}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <Link to="/obstetricia">
                    Obstetricia de bajo y alto riesgo
                  </Link>
                </li>
                <li>
                  <Link to="/ginecologia">Ginecología</Link>
                </li>
                <li>
                  <Link to="/anticoncepcion">
                    Anticoncepción o planificación familiar
                  </Link>
                </li>
                <li>
                  <Link to="/lactancia">Lactancia materna</Link>
                </li>
              </ul>
            </Collapsible>

            <Collapsible
              trigger={Doctors}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <Collapsible
                trigger={<span className="sub__menu">Obstetricia</span>}
                triggerTagName="div"
                triggerOpenedClassName="icon_close"
                triggerClassName="iconAdd"
                open={false}
              >
                <ul className="sidebar_sub_menu text-white mt-3">
                  <li>
                    <Link to="/transvaginal">
                      Obstétrica transvaginal o temprana
                    </Link>
                  </li>
                  <li>
                    <Link to="/transabdominal">Obstétrica transabdominal</Link>
                  </li>
                  <li>
                    <Link to="/biofisico">Obstétrica con perfil biofísico</Link>
                  </li>
                  <li>
                    <Link to="/tamizaje">
                      Tamizaje genético o Translucencia nucal
                    </Link>
                  </li>
                  <li>
                    <Link to="/detalle_anatomico">Detalle anatómico</Link>
                  </li>
                  <li>
                    <Link to="/cervicometria">Cervicometría</Link>
                  </li>
                  <li>
                    <Link to="/doppler_fetoplacentario">
                      Doppler fetoplacentario
                    </Link>
                  </li>
                  <li>
                    <Link to="/ecografia_3d_4d">Ecografía 3D – 4D</Link>
                  </li>
                </ul>
              </Collapsible>
              <Collapsible
                trigger={<span className="sub__menu">Ginecología</span>}
                triggerTagName="div"
                triggerOpenedClassName="icon_close"
                triggerClassName="iconAdd"
                open={false}
              >
                <ul className="sidebar_sub_menu text-white mt-3">
                  <li>
                    <Link to="/ecografia/transvaginal">
                      Ecografía pélvica transvaginal
                    </Link>
                  </li>
                  <li>
                    <Link to="/ecografia/transabdominal">
                      Ecografía pélvica transabdominal
                    </Link>
                  </li>
                </ul>
              </Collapsible>
            </Collapsible>

            <Collapsible
              trigger={News}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <Link to="/other-citologia">Toma de citología vaginal</Link>
                </li>
                <li>
                  <Link to="/other-diu">
                    Inserción y retiro de dispositivo intrauterino
                  </Link>
                </li>
                <li>
                  <Link to="/other-subdermico">
                    Inserción y retiro de implante subdérmico
                  </Link>
                </li>
                <li>
                  <Link to="/other-test_adn_vph">Toma de test ADN VPH</Link>
                </li>
              </ul>
            </Collapsible>

            <Collapsible
              trigger={Pages}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <NavLink to="/doctorDetails">Acerca de mi</NavLink>
                </li>
              </ul>
            </Collapsible>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;
