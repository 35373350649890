import React from "react";

const HomeFooter = () => {
  return (
    <>
      <footer>
        <div className="footer-top primary-bg pt-115 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 footer__contact">
                <div className="f-contact-info-box fix mb-30">
                  <div className="footer-co-icon f-left">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                  </div>
                  <div className="footer-co-content">
                    <h4> 3153260152 - 3142720950</h4>
                  </div>
                </div>
                <div className="f-contact-info-box fix mb-30">
                  <div className="footer-co-icon f-left">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </div>
                  <div className="footer-co-content">
                    <h4>drazubieta@gmail.com</h4>
                  </div>
                </div>
                <div className="f-contact-info-box fix mb-30">
                  <div className="footer-co-icon f-left">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </div>
                  <div className="footer-co-content">
                    <h4>
                      Calle 12 # 20-65 Policlínico famisalud <br />
                      San José del Guaviare
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="footer-widget  mb-40">
                  <div className="footer-title">
                    <h3>Horario de atención</h3>
                  </div>
                  <div className="h4events-list mb-30">
                    <ul>
                      <li>
                        <i className="fal fa-clock"></i>
                        <span>
                          Lunes - Viernes{" "}
                          <span>8:00am - 12:00pm y 2:00pm - 5:00pm</span>
                        </span>
                      </li>
                      <li>
                        <i className="fal fa-clock"></i>
                        <span>
                          Sabado <span>8:00am - 12:00pm</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="footer__social h4footer-social">
                    <ul className="list-inline">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom pt-25 pb-20">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-copyright text-center">
                  <p className="white-color">@ 2022 - Dra. Judith Zubieta</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomeFooter;
