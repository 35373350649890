import React from "react";
import { Link } from "react-router-dom";

const BlogDetailsLeftSide = ({ details_topBar, brigade }) => {
  console.log("brigade -> ", brigade);
  return (
    <>
      <div className="col-lg-12">
        <article className="postbox post format-image mb-40">
          {details_topBar}

          <div className="postbox__text bg-none">
            {brigade === "1" && (
              <>
                <div className="post-meta mb-15">
                  <span>
                    <i className="far fa-calendar-check"></i> Noviembre 14, 2021{" "}
                  </span>
                  <span>
                    <Link to="/blogDetails">
                      <i className="far fa-user"></i> San Jose del Guaviare
                    </Link>
                  </span>
                </div>
                <h3 className="blog-title">CRECIENDO JUNTOS POR EL GUAVIARE</h3>
                <div className="post-text mb-20">
                  <p>
                    Adelantamos una atención humanitaria a comunidad vulnerable
                    del sector de Baja Unión del Municipio de San José del
                    Guaviare.
                  </p>
                  <p>
                    Gracias al equipo de los profesionales que donaron su
                    jornada laboral para poder adelantar esta brigada ….
                    Igualmente, a todas las entidades que se vincularon con las
                    diferentes donaciones que se hicieron, CTA PROFESIONALES,
                    COTREGUA, SECRETARIA DE SALUD MUNICIPAL, LABORATORIO GAMMA,
                    ESE PRIMER NIVEL DE ATENCION EN SALUD, ICBF, INSTITUCION
                    EDUCATIVA BAJA UNION, FEDERACION FENACHO y ACCION INTEGRAL –
                    BRIGADA 22 del EJERCITO NACIONAL.
                  </p>

                  <p>
                    Actividades en que fue atendida esta comunidad fue en
                    actividades lúdicas para niños, recreación, peluquería,
                    vacunación, medicina general, odontología, pediatría,
                    optometría, nutrición, ginecología, fisioterapia,
                    laboratorio clínico y nutrición.
                  </p>
                  <p>Población atendida 195 personas.</p>
                </div>
              </>
            )}

            {brigade === "2" && (
              <>
                <h3 className="blog-title">Segunda brigada 5 de marzo 2022</h3>
                <div className="post-text mb-20">
                  <p>
                    CRECIENDO JUNTOS POR EL GUAVIARE con el apoyo de diferentes
                    entidades atendió a la comunidad de la CEIBA en los
                    servicios de Ginecologia, vacunación, pediatría, medicina
                    general odontologia , nutrición, terapia física y
                    laboratorio clínico.
                  </p>
                </div>
              </>
            )}
          </div>
        </article>
      </div>
    </>
  );
};

export default BlogDetailsLeftSide;
