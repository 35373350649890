import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../../components/Shared/Footer";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const EcoTrasnvaginal = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Ecografía pélvica transvaginal " />
      <section className="about-area pt-50 pb-50">
        <div className="container">
          <div className="row ">
            {/* <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="section-title section-title-m-0 pos-rel  text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="Foto embarazada sentada hablando ...."
                    src="img/services/transabdominal.png"
                  />
                </div>
              </div>
            </div> */}
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="facalty-text">
                <p>
                  Esta ecografía permite examinar los órganos genitales internos
                  de una mujer, incluyendo el útero, los ovarios, el cuello
                  uterino y la vagina. De gran utilidad en dolor pélvico,
                  sangrados anormales, seguimiento de patología benigna (
                  quistes ováricos, miomas uterinos )
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default EcoTrasnvaginal;
