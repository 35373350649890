import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Citologia = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Toma de Citología" />
      <section className="about-area pt-40 pb-40">
        <div className="container">
          <div className="row ">
            {/* <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="section-title section-title-m-0 pos-rel text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="Toma de Citología Img"
                    src="img/services/tamaddizaje_mix.png"
                  />
                </div>
              </div>
            </div> */}
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  También llamada Prueba de Papanicolau es la técnica de
                  tamizaje más utilizada para detección precoz de cáncer de
                  cuello uterino y lesiones precancerosas, mediante el análisis
                  anatomopatológico de las células del cuello del útero. Si su
                  resultado es positivo o anormal, requiere la realización de
                  colposcopia biopsia para el diagnostico defintiivo de cáncer
                  de cuello de útero.
                </p>
                <p>
                  Mediante la citología vaginal también se obtienen resultados
                  sobre la posible existencia de infecciones vaginales.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Citologia;
