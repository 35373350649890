import React from "react";
import { useParams } from "react-router-dom";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import BlogDetailsArea from "./BlogDetailsArea/BlogDetailsArea";

const BlogDetails = () => {
  const { brigade } = useParams();
  console.log("brigade: ", brigade);
  return (
    <>
      <HomeHeader />
      <CommonPageHeader
        title={
          brigade === "1"
            ? "Primera brigada 14 de noviembre 2021"
            : "Segunda brigada 5 de marzo 2022"
        }
        subtitle="Primero la gente"
      />
      <BlogDetailsArea brigade={brigade} />
      <HomeFooter />
    </>
  );
};

export default BlogDetails;
