import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Transvaginal = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Obstétrica transvaginal o temprana" />
      <section className="about-area pt-60 pb-80">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="section-title section-title-m-0 pos-rel  text-end d-flex align-items-center">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="Consulta de obstetricia"
                    src="img/services/obstetrica_transvaginal.png"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  Se realiza con el fin de verificar la ubicación del embarazo
                  dentro o fuera del útero (ectópico), establecer la edad
                  gestacional, definir el número de embriones y reconocer su
                  vitalidad. Idealmente debe realizarse a partir de las 7
                  semanas para medición directa del embrión y de esta manera
                  planificar la toma de la ecografía de tamizaje genético.
                  <br />
                  <br />
                  También puede realizarse ante dolor pélvico y sangrado
                  temprano de la gestación.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Transvaginal;
