import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";
import ServicesDetailsArea from "./ServicesDetailsArea/ServicesDetailsArea";

const ServicesDetails = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Consultas" subtitle="Details" />
      <ServicesDetailsArea />
      <HomeFooter />
    </>
  );
};

export default ServicesDetails;
