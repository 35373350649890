import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Doppler = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Doppler Fetoplacentario" />
      <section className="about-area pt-40 pb-40">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="section-title section-title-m-0 pos-rel text-end">
                <div className="section-text section-text-small pos-rel">
                  <img alt="Doppler Img" src="img/services/doppler.png" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  En esta ecografía se establece el bienestar fetal mediante la
                  evaluación del paso de la sangre a través los vasos sanguíneos
                  de la madre, del bebe o de la placenta, que pudieran
                  interferir en la salud de la madre, él bebe o el crecimiento
                  del mismo.
                </p>
                <p>
                  Indicado especialmente en mujeres con factores de riesgo
                  (hipertensión arterial, diabetes mellitus, obesidad, cesárea
                  previa) y clasificadas en su control prenatal como pacientes
                  de alto riesgo obstétrico.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Doppler;
