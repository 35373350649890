import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const DetalleAnatomico = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Detalle anatómico" />
      <section className="about-area pt-40 pb-40">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center justify-content-center">
              <div className="section-title section-title-m-0  text-center">
                <div className="section-text section-text-small">
                  <figure className="d-flex align-items-center justify-content-center">
                    <img
                      alt="Detalle anatómico Img"
                      src="img/services/detalle_anatomico.png"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  Realizada entre las semanas 20 a 23+6 días como máximo, se
                  realiza una valoración exhaustiva de la anatomía del bebe para
                  confirmar que sus órganos están bien formados, y además se
                  buscan imágenes especiales (marcadores de alteraciones
                  cromosómicas) como pliegue nucal, y presencia o no de hueso
                  nasal, que aumenta la posibilidad de detección del Síndrome
                  Down (“Mongolismo”) y otras anomalías fetales.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default DetalleAnatomico;
