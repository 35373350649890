import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../../components/Shared/Footer";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Obstetricia = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Consulta de obstetricia de bajo y alto riesgo" />
      <section className="about-area pt-80 pb-120">
        <div className="container">
          <div className="row ">
            {/* <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="section-title section-title-m-0 pos-rel text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="Foto embarazada sentada hablando ...."
                    src="img/services/obstetrdadaicia_mix.png"
                  />
                </div>
              </div>
            </div> */}
            <div className="col-xl-12 col-lg-12 col-md-12 d-flex align-items-center">
              <div className="facalty-text">
                <p>
                  La obstetricia es la rama de la medicina en la cual se valora
                  y cuida a la Mujer en el período Preconcepcional, Gestación,
                  Parto y Post parto. En el control prenatal se realiza una
                  historia clínica completa para determinar los riesgos durante
                  el embarazo, el parto y el posparto, se programan posteriores
                  valoraciones, toma de laboratorios y ecografías, para lograr
                  que la gestación transcurra sin contratiempos, y en el caso de
                  que la madre tenga alguna alteración, controlar y tratar con
                  agilidad. requiere controles periódicos de acuerdo con cada
                  circunstancia individual, con la finalidad de brindar hijos
                  sanos y familias felices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Obstetricia;
