import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";

import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Ginecologia = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Ginecología" />
      <section className="about-area pt-80 pb-120">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
              <div className="section-title section-title-m-0 pos-rel text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="Consulta de ginecologia"
                    className="round-image"
                    src="img/services/ginecologia.jfif"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex ">
              <div className="facalty-text">
                <p>
                  La ginecología es la rama de la medicina que estudia las
                  enfermedades de la Mujer principalmente en la esfera de su
                  vida hormonal, genital y sicosomática. Desde la infancia hasta
                  la menopausia.
                  <br />
                  <br />
                  En esta consulta el objetivo será la prevención, diagnóstico y
                  manejo de enfermedades infecciosas, hormonales, cáncer,
                  enfermedades cardíacas, osteoporosis y todo tipo de
                  enfermedades clínico-quirúrgicas que puedan afectar a la
                  mujer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Ginecologia;
