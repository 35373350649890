import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Lactancia = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Lactancia materna" />
      <section className="about-area pt-80 pb-60">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="section-title section-title-m-0 pos-rel mb-50 text-end">
                <div className="section-text section-text-small pos-rel">
                  <img
                    alt="Lactancia materna"
                    src="img/services/lactancia.jpg"
                    className="round-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="facalty-text">
                <p>
                  En esta consulta se brindará asesoría, resolución de dudas
                  acerca de la lactancia materna, técnicas para amamantar,
                  beneficios, complicaciones frecuentes y principales retos para
                  los nuevos padres, y hacer de éste un proceso gratificante y
                  feliz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
    </>
  );
};

export default Lactancia;
