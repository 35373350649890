import React from "react";
import { Button, Modal, ModalDialog } from "react-bootstrap";
import HomeAboutArea from "./HomeAboutArea/HomeAboutArea";
import HomeBlogs from "./HomeBlogs/HomeBlogs";
import HomeBlogsTwo from "./HomeBlogs/HomeBlogsTwo";
import HomeCta from "./HomeCta/HomeCta";
import HomeFact from "./HomeFact/HomeFact";
import HomeFooter from "./HomeFooter/HomeFooter";
import HomeHeader from "./HomeHeader/HomeHeader";
import HomeHeroSection from "./HomeHeroSection/HomeHeroSection";
import HomeOurTeam from "./HomeOurTeam/HomeOurTeam";
import HomePricing from "./HomePricing/HomePricing";
import HomeServices from "./HomeServices/HomeServices";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="aniversario"
      centered
    >


      <img  src="img/promocion/aniversario.jpeg" alt="Aniversario"></img>

    </Modal>
  );
}


const Home = () => {

  const [modalShow, setModalShow] = React.useState(true);

  return (
    <>
      

      
      <HomeHeader />
      <HomeHeroSection />
      <HomeAboutArea />
      <HomeServices />
      {/* <HomeOurTeam /> */}
      {/* <HomeFact /> */}
      {/* <HomePricing/> */}
      <HomeCta />
      <HomeBlogs />
      <HomeBlogsTwo />
      <HomeFooter />
    </>
  );
};

export default Home;
