import React from "react";

const ServicesDetailsArea = () => {
  return (
    <>
      <div className="service-details-area pt-80 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <article className="service-details-box">
                <div className="service-details-text mb-30">
                  <p>
                    Sabias que es importante visitar regularmente a tu
                    ginecólogo y obstetra, de esta manera puedes prevenir,
                    diagnosticar y tratar oportunamente:
                  </p>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      * Enfermedades infecciosas (flujo de mal olor o comezón
                      vaginal, lesiones en zona genital o las mamas,
                      enfermedades de transmisión sexual)
                    </li>
                    <li class="list-group-item">
                      * Alteraciones hormonales (alteraciones del ciclo
                      menstrual, hemorragias, quistes de ovario)
                    </li>
                    <li class="list-group-item">
                      * Dolor pélvico, infertilidad, cáncer genital. Recibir
                      apoyo y cuidados específicos mientras creas vida...
                    </li>
                    <li class="list-group-item">
                      * Planear tu embarazo en un estado óptimo de bienestar
                    </li>
                    <li class="list-group-item">
                      * Realizarte tus controles periodicos durante el embarazo
                      para establecer factores que puedan afectarte a ti o a tu
                      bebé y tratarlos a tiempo.
                    </li>
                    <li class="list-group-item">
                      * Control posparto y asesoría en lactancia materna ...
                    </li>
                  </ul>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesDetailsArea;
